@import "../../utils/includes.scss";

//
// Fonts
//

$fontFamily: 'antenna-regular', sans-serif;
$boldFontFamily: 'antenna-bold', sans-serif;
$boldFontWeight: normal;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

//
// Colors
//

$textColor: #333333;
$linkColor: #00426B;
$labelColor: #91a4b1;
$formColor: #333333;
$utilColor: #00426B;
$priceColor: #00426B;
$lightColor: #555555;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #16181f;
$titleSecondaryColor: #ffffff;
$subtitleColor: #333333;
$subtitleSecondaryColor: #ffffff;
$successColor: #00426B;
$warningColor: #00426b;
$errorColor: #ff3526;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #003478;
$colorBtnAlpha: #fff;
$bgBtnBeta: #91a4b1;
$colorBtnBeta: #fff;
$colorBtnOutlineAlpha: #555555;
$colorBtnOutlineBeta: #0276B3;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 0px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #16181e;
$calculatorTabsTextColor: #dedede;
$calculatorCloseTabs: #505050;
$calculatorCloseTabsColor: #e3e3e3;
$calculatorMainColor: #ffffff;
$calculatorButton: #003478;

$garageBackground: #ffffff;
$garageTextColor: #333333;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #1b1b1b;
$colorTopbarLogin: #c8c9cb;

$progressBarTitleBG: #00426b;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #00426b;
$progressBarCloseButtonBG: #003478 ;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #171921;
$paymentCalculatorClosedColor: #e8e8e8;
$paymentCalculatorPanelBG: #21232E;
$paymentCalculatorTermWrapperBG: #363a4d;
$paymentCalculatorTermWrapperColor: #91a4b1;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #252835;
$paymentCalculatorTermColor: #91a4b1;

$priceFinancingColor: #00426B ;
$bluePrimaryColor: #00426B;
$srTextGrey: #707070;
$srButton1Background: #102B4E;

$srButton2Background: #ffffff;
$srButton2BorderColor: #0276b3;
$srButton2Color: #0276b3;

$srButton2BackgroundHover: #333333;
$srButton2BorderColorHover: #333333;
$srButton2ColorHover: #ffffff;

//
// Styles
//
@include breakpoint(mobile){
    .widget-sr{
        &.makes__ford{
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha;
                .is-price{
                    color: $colorBtnAlpha;
                }
                span{
                    color: $colorBtnAlpha;
                }
            }
        }
    }
}
.widget-sr{
    @include clearfix;
    width: 100%;

    &.makes__ford{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        .listing-used-filters-bloc.listing-used-metadata-container .metadata-list .metadata-button.selected{
            border: 2px solid $linkColor;
            color: $linkColor;
        }
        .is-price{
            color: $priceFinancingColor !important;
            border-color: $priceFinancingColor !important;
        }
        .section-showroom-header__progressBar-stage.selected{
            .progressBar-stage__active-effect{
                color: $bluePrimaryColor;
                fill: $bluePrimaryColor;
            }
        }
        .has-price-background{
            background-color: $bluePrimaryColor ;
        }
        .is-light{
            color: #555555 !important;
        }
        .sr-link{
            color: $bluePrimaryColor;
        }
        .is-util{
            color: $bluePrimaryColor;
        }
        .listing-tile{
            .tile-tag,
            .listing-tile-car-save{
                background-color: #00426b !important;
                color: #ffffff !important;
            }
        }
        .message-handler{
            &.warning,
            &.success{
                background-color: #00426b;
            }
        }
        .sr-button-1{
            background: $srButton1Background;
        }
        .sr-button-2{
            border: 1px solid $srButton2BorderColor !important;
            background: $srButton2Background !important;
            color: $srButton2Color !important;
            &.ishover,
            &.ishover:hover{
                border: 1px solid $srButton2BorderColorHover !important;
                background: $srButton2BackgroundHover !important;
                color: $srButton2ColorHover !important;
            }
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate{
            border-bottom-color: $bluePrimaryColor !important;
        }
          .TransactionSummaryUnit--single .validFlag:before{
            background-image: -webkit-linear-gradient(304deg,transparent 50%,$bluePrimaryColor 55%) !important;
          }
        .TransactionProgressBar--progression{
            background-color: $bluePrimaryColor !important;
        }
        .accessory_wrapper.single .accessory_wrapper--check icon{
            color: $bluePrimaryColor !important;
        }


        /*****************************
            Accessibility
        ****************************/
        .tile-payment-info .payment-rebates{
            .payment-rebate{
                color: #555555;
            }
        }
        .cash-section.tile-payment-info{
            .payment-row{
                color: #555555;
            }
        }
        .listing-used-payment-wrap.cash{
            .listing-used-cash-down,
            .listing-used-max-term,
            .payment-selector,
            .range-options.disabled,
            .listing-used-payment-frequency,
            .listing-used-desired-payment,
            .forms_disabled,
            .payment-selector{
                opacity:.8;
            }
        }
        .listing-used-filters-bloc.listing-used-metadata-container {
            .metadata-button{
                color: #555555;
            }
        }
        .listing-used{
            .listing-new-tile{
                .listing-tile-package-description,
                .listing-tile-vin,
                .listing-tile-specification-stock,
                .listing-tile-specification-odometer,
                .listing-new-tile-drivePowerTrains{
                    color: #555555;
                }
            }
            .styled-placeholder.dropdown.not-empty{
                .input-label{
                    color: #555555;
                }
            }
        }
        .catalog{
            .section-catalog-filter{
                .input-label,
                .catalog-filter-type-list--single{
                    color: #555555;
                }
            }
            .vehicle-item {
                .taxes-detail{
                    color: #555555;
                }
            }
        }
        .showroom-build-and-price__inner-content{
            .section-showroom-header__progressBar-stage{
                color: #555555;
                &.selected{
                    .progressBar-stage__content--name{
                        color: white;
                    }
                }
                .icon-showroom{
                    color: #555555;
                }
            }
            .selection-year-bloc__disclaimer{
                color: #555555;
                span:first-child{
                    color: #555555;
                }
            }
        }


        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper{
            .TransactionSummaryUnit--single.is-validate{
                border-bottom-color: #0276b3!important;
            }
        }
        .TransactionSummaryUnit--single{
            .validFlag:before{
                background-color: transparent;
                background-image: -webkit-linear-gradient(304deg,transparent 50%,#0276b3 55%) !important;
            }
        }
        .TransactionProgressBar--progression{
            background-color: #0276b3 !important;
        }
        .confirm_deposit--icon,
        .confirm_financing--icon{
            border: 1px solid #0276b3 !important;
            .icon,.icon-Crochet{
                color: #0276b3 !important;
            }
        }
        .confirm_deposit--title,
        .confirm_financing--title{
            color: #0276b3;
        }
        .section-checkout-header__progressBar-stage.validated{
            .picto-showroom:first-child{
                color: #0276b3;
            }
        }
        .Appointment__content .appointment__steps--single.is-validated {
            .icon-calendar{
                color: #0276b3 !important;
            }
        }
        .doneLabel {
            color: #0276b3 !important;
            .icon-Crochet{
                color: #0276b3 !important;
            }
        }

        .message-handler{
            &.warning,
            &.success,{
                background-color: #0276b3 !important;
            }
        }
        .has-success-background{
            background-color: #0276b3 !important;
        }

        /*****************************
            Accessibility
        ****************************/
        .tile-payment-info .payment-rebates{
            .payment-rebate{
                color: #555555;
            }
        }
        .cash-section.tile-payment-info{
            .payment-row{
                color: #555555;
            }
        }
        .listing-used-payment-wrap.cash{
            .listing-used-cash-down,
            .listing-used-max-term,
            .payment-selector,
            .range-options.disabled,
            .listing-used-payment-frequency,
            .listing-used-desired-payment,
            .forms_disabled,
            .payment-selector{
                opacity:.8;
            }
        }
        .listing-used-filters-bloc.listing-used-metadata-container {
            .metadata-button{
                color: #555555;
            }
        }
        .listing-used{
            .listing-new-tile{
                .listing-tile-package-description,
                .listing-tile-vin,
                .listing-tile-specification-stock,
                .listing-tile-specification-odometer,
                .listing-new-tile-drivePowerTrains{
                    color: #555555;
                }
            }
            .styled-placeholder.dropdown.not-empty{
                .input-label{
                    color: #555555;
                }
            }
        }
        .catalog{
            .section-catalog-filter{
                .input-label,
                .catalog-filter-type-list--single{
                    color: #555555;
                }
            }
            .vehicle-item {
                .taxes-detail{
                    color: #555555;
                }
            }
        }
        .showroom-build-and-price__inner-content{
            .section-showroom-header__progressBar-stage{
                color: #555555;
                &.selected{
                    .progressBar-stage__content--name{
                        color: white;
                    }
                }
                .icon-showroom{
                    color: #555555;
                }
            }
            .selection-year-bloc__disclaimer{
                color: #555555;
                span:first-child{
                    color: #555555;
                }
            }
        }


        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper{
            .TransactionSummaryUnit--single.is-validate{
                border-bottom-color: #0276b3!important;
            }
        }
        .TransactionSummaryUnit--single{
            .validFlag:before{
                background-color: transparent;
                background-image: -webkit-linear-gradient(304deg,transparent 50%,#0276b3 55%) !important;
            }
        }
        .TransactionProgressBar--progression{
            background-color: #0276b3 !important;
        }
        .confirm_deposit--icon,
        .confirm_financing--icon{
            border: 1px solid #0276b3 !important;
            .icon,.icon-Crochet{
                color: #0276b3 !important;
            }
        }
        .confirm_deposit--title,
        .confirm_financing--title{
            color: #0276b3;
        }
        .section-checkout-header__progressBar-stage.validated{
            .picto-showroom:first-child{
                color: #0276b3;
            }
        }
        .Appointment__content .appointment__steps--single.is-validated {
            .icon-calendar{
                color: #0276b3 !important;
            }
        }
        .doneLabel {
            color: #0276b3 !important;
            .icon-Crochet{
                color: #0276b3 !important;
            }
        }

        .message-handler{
            &.warning,
            &.success,{
                background-color: #0276b3 !important;
            }
        }
        .has-success-background{
            background-color: #0276b3 !important;
        }

        /**********************
            Accessibility
        ***********************/
        .model-button{
            color: #232323 !important;
        }
        .car-equipments_summary .specs-bloc--content span{
            color: #555555 !important;
        }
        .vehicle-overview__summary{
            .financing-section{

            }
        }
        .financing-section{
            .tile-payment-rate .frequency-highlight,
            .tile-payment .tile-payment-detail .tile-payment-detail-wrapper{
                color: #555555 !important;
            }
        }
        .paymentCalculator__wrapper,
        .frequencies-panel-wrapper.is-open{
            .is-light,
            .customize-payment-title,
            .calculator-cash-down-title{
                color: #cccccc !important;
            }
            .is-util{
                color: #0276b3 !important;
            }
            .payment-detail.is-light{
                color: #555 !important;
            }
        }
        .bloc-options__single--body .option-list .option,
        .TradeInTile--content-official-subtitle{
            color: #555555 !important;
        }
        .catalog-filter-type-list--single{
            &.selected{
                color: #000000 !important;
            }
        }
        .listing-new-tile .listing-new-tile-drivePowerTrains .new-car-tagline p{
            color: #00426b !important;
        }
        .listing-tile .listing-tile-drivePowerTrains{
            color: #555555 !important;
        }
        .section-showroom-header{
            .payment-calculator-wrapper{
                .paymentCalculator__payment{
                    .is-util{
                        color: #FFFFFF !important;
                    }
                }
            }
        }
        .new-customize-payment{
            .frequencies-panel-wrapper.is-open .payment-detail.is-light{
                color:#ffffff !important;
            }
        }

        .InputText.styled-placeholder.not-empty .input-label,
        .Appointment__content .appointment__steps--single .timeSaved{
            color: #555555 !important;
        }

        .errors-input{
            color: #cc0033 !important;
        }

        .seling-price-disclaimer{
            color: #555555 !important;
        }

        .showroom-vdp-used-features-vehicle-info-title{
            color: #555555 !important;
        }
        .showroom-vdp-used-features-vehicle-info{
            icon{
                color: #555555 !important;
            }
        }
        .showroom-vdp-used-features-vehicle-info-fuel-info{
            color: #555555 !important;
        }
        .payment-calculator-wrapper{
            z-index: auto;
        }
        .listing-used-filters-model{
            opacity:.7 !important;
        }
        .listing-used-filters-model.isAvailable{
            opacity:1 !important;
        }
        .listing-new-tile-image{
            .tile-tag{
                background: #003478 !important;
            }
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        .payment-calculator-wrapper{
            z-index: auto;
        }

        &.trade-is-on-top{
            .listing-used-car-search>div:last-child {
                display: flex;
                flex-direction: column;
                #instant-estimate-bar-wrapper{
                    order: 1;
                    margin-top: 0 !important;
                }
                .listing-tiles{
                    order: 2;
                }
                .listing-used-button-loading{
                    order: 3;
                }
            }
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(desktop){
    .widget-sr{
        &.makes__ford{
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}
